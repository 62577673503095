<template>
  <div class="certificate-form">
    <slot name="certificate-form">
      <div class="row">
        <div class="col-lg-6 col-12 mb-4">
          <Input
            :label="$t('talentWizard.certificateName')"
            :placeholder="$t('talentWizard.certificateName')"
            :validationField="$v.certificate.name"
            :params="[
              'required',
              'alphaNumSpace',
              { maxLength: { number: '50' } },
            ]"
            isAstrik="true"
          />
        </div>
        <div class="col-lg-6 col-12 mb-4">
          <Input
            :label="$t('talentWizard.issuingOrganization')"
            :placeholder="$t('talentWizard.issuingOrganization')"
            :validationField="$v.certificate.organization"
            :params="['alphaNumSpace', { maxLength: { number: '50' } }]"
          />
        </div>
        <div class="col-12 mb-0 mb-md-4">
          <OneCheckBoxInput
            :ClassLabel="'control control--checkbox'"
            :labelText="$t('talentWizard.thisCredentialDoesNotExist')"
            :checked="certificate.not_expire"
            @input="inputChecked"
          />
        </div>
        <div class="col-sm-6 col-12 mb-0 mb-md-4">
          <div class="row">
            <div class="col-12">
              <label class="form-label">{{$t('talentWizard.issuingOrganization')}}</label>
            </div>
            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <MonthsDropDown
                :className="'style-chooser'"
                @input="setStartMonth"
                :default="certificate.start_month"
              />
            </div>

            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <YearsDropDown
                :className="'style-chooser'"
                @input="setStartYear"
                :default="certificate.start_year"
              />
            </div>
          </div>
          <div class="row">
            <span style="color: #ff6f59">{{ futureDateError }}</span>
            <span style="color: #ff6f59" v-if="!futureDateError">{{
              startDateError
            }}</span>
          </div>
        </div>
        <div class="col-sm-6 col-12 mb-0 mb-md-4">
          <div class="row">
            <div class="col-12">
              <label class="form-label"> {{$t('talentWizard.expirationDate')}} </label>
            </div>
            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <MonthsDropDown
                :className="'style-chooser'"
                @input="setEndMonth"
                :isDisabled="certificate.not_expire"
                :default="certificate.end_month"
              />
            </div>
            <div class="col-sm-6 col-12 mb-sm-0 mb-3">
              <YearsDropDown
                :className="'style-chooser'"
                @input="setEndYear"
                :isDisabled="certificate.not_expire"
                :default="certificate.end_year"
              />
            </div>
          </div>
          <div class="row">
            <span style="color: #ff6f59">{{ endDateError }}</span>
          </div>
        </div>
        <div class="col-12 mb-4">
          <Input
            :label="$t('talentWizard.credentialId') "
            :placeholder="$t('talentWizard.credentialId')"
            :validationField="$v.certificate.identifier"
            :params="[{ maxLength: { number: '50' } }]"
          />
        </div>
        <div class="col-12 mb-4">
          <Input
            :label="$t('talentWizard.credentialUrl')"
            :placeholder="$t('talentWizard.credentialUrl')"
            :validationField="$v.certificate.url"
            :params="[{ maxLength: { number: '2000' } }]"
            @handleChange="(e) => handleUrlChange(e)"
          />
          <span v-if="!isValidUrl" class="error"> {{ $t('talentWizard.invalidlink') }} </span>
        </div>
        <span style="display: none">{{ validForm() }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
import {
  validateStartDate,
  validateEndDate,
} from "../../../../../functions/DateFunctions";
import { deepUrlValidation } from "../../../../../utils/utils";
import OneCheckBoxInput from "../../../../../components/shared/OneCheckBoxInput/OneCheckBoxInput";
import MonthsDropDown from "../../../../../components/shared/MonthsDropdown/MonthsDropdown";
import YearsDropDown from "../../../../../components/shared/YearsDropdown/YearsDropdown";
import Input from "../../../../../components/shared/formControls/input/input.vue";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
const alphaNumSpace = helpers.regex("alphaNumSpace", /^[A-Za-z0-9 ]+$/);
import "./certificatesForm.scss";

export default {
  props: [
    "certificateDetails",
    "certificateEndDateError",
    "certificateFutureDateError",
    "certificateStartDateError",
  ],
  components: {
    Input,
    OneCheckBoxInput,
    MonthsDropDown,
    YearsDropDown,
  },
  data() {
    return {
      startDateError: this.certificateStartDateError,
      futureDateError: this.certificateFutureDateError,
      endDateError: this.certificateEndDateError,
      certificate: this.certificateDetails,
      isValidUrl: true,
    };
  },
  validations: {
    certificate: {
      name: {
        required,
        maxLength: maxLength(100),
        alphaNumSpace,
      },
      organization: {
        maxLength: maxLength(100),
        alphaNumSpace,
      },
      identifier: {
        maxLength: maxLength(100),
      },
      url: {
        maxLength: maxLength(2000),
      },
    },
  },

  methods: {
    inputChecked(value) {
      this.certificate.not_expire = value;
      if (value == 1) {
        this.startDateError = "";
        this.endDateError = "";
        this.certificate.end_month = "";
        this.certificate.end_year = "";
      }
    },
    setStartMonth(value) {
      this.certificate.start_month = value;
      this.callvalidateStartDate();
    },
    setEndMonth(value) {
      this.certificate.end_month = value;
      this.callvalidateEndDate();
    },
    setStartYear(value) {
      this.certificate.start_year = value;
      this.callvalidateStartDate();
    },
    setEndYear(value) {
      this.certificate.end_year = value;
      this.callvalidateEndDate();
    },
    callvalidateStartDate() {
      let res = validateStartDate(
        this.certificate.start_month,
        this.certificate.start_year,
        this.certificate.end_month,
        this.certificate.end_year
      );
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
      this.futureDateError =  res.futureDateError ?this.$t(`validation.${[res.futureDateError]}`) : ''
    },
    callvalidateEndDate() {
      let res = validateEndDate(
        this.certificate.start_month,
        this.certificate.start_year,
        this.certificate.end_month,
        this.certificate.end_year
      );
      this.startDateError = res.startDateError ? this.$t(`validation.${[res.startDateError]}`) : ''
      this.endDateError =  res.endDateError ?  this.$t(`validation.${[res.endDateError]}`) : ''
    },
    handleSubmitCertificatesForm() {
      if (this.certificate?.id == null || this.certificate?.id == undefined) {
        this.$store
          .dispatch("talentProfile/addCertificateRequest", this.certificate)
          .then(() => {
            this.$emit("handleCloseModal", false);
            if (this.resetFormData) this.resetFormData();
          });
      } else {
        this.$store
          .dispatch("talentProfile/updateCertificateRequest", this.certificate)
          .then(() => {
            this.$emit("handleCloseModal", false);
          });
      }
    },
    handleUrlChange(e) {
      if (e.target.value == "") {
        this.isValidUrl = true;
      } else {
        const enteredValue =
          e.target.value.startsWith("https://") ||
          e.target.value.startsWith("http://")
            ? e.target.value
            : `https://${e.target.value}`;
        this.isValidUrl = !deepUrlValidation(enteredValue);
      }
    },
    validForm() {
      const isDisabled =
        this.$v.$invalid ||
        !this.isValidUrl ||
        !!this.startDateError ||
        !!this.futureDateError ||
        !!this.endDateError 

      this.$emit("handleIsDisabled", isDisabled);
      return isDisabled;
    },
  },
};
</script>

<style lang="scss" scoped></style>
