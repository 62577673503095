<template>
  <div class="talent-profile__wrapper">
    <div class="container text-center">
      <div class="row" ref="">
        <div class="col-md-12" v-if="profile.basicInfo?.is_availability_expired || profile.basicInfo?.is_salary_expired" >
          <div class="tw-px-8  tw-mt-4 tw-text-start tw-py-4 tw-bg-[#FF6F5933] tw-rounded-2xl tw-justify-start tw-items-start tw-gap-2 tw-flex tw-flex-col ">
          <div class="tw-flex tw-flex-row tw-items-center">
            <img src="@/assets/images/mark 2.svg" alt="talent-profile-icon" class="tw-w-6 tw-h-6 tw-me-2" />
            <h3 class="tw-text-xl tw-text-[#ff6f59] tw-font-bold " >
              {{ $t("home.profileUpdateTitle") }}
            </h3>
          </div>
          <p class="tw-flex tw-flex-row tw-text-black tw-text-base tw-font-normal tw-w-full md:tw-w-11/12">
            {{ $t("home.profileUpdateMessage") }}
          </p>
        </div>
        </div>
        

        <div class="text-center col-md-12 col-lg-4">
          <ProfileCard
            :profile="profile"
            :isLoaded="isLoaded"
            @route-changed="handleRedirection($event)"
            @reload-profile="getProfileDataRequest()"
          />
        </div>
        <div class="mb-5 col-md-12 col-lg-8">
          <Assessments
            :isLoaded="isLoaded"
            @assessments-loaded="assessmentsLoaded = true"
            :class="{ 'mb-3': !isLoaded }"
          />
          <div class="align-items-center">
            <Tabs :isLoaded="isLoaded" />
            <div
              class="tab-content skills-tab-content skills-tab-content-view"
              id="pills-tabContent"
            >
              <div v-if="!isLoaded">
                <div
                  class="my-3 d-flex align-items-center justify-content-center"
                  v-for="loader in 6"
                  :key="loader"
                >
                  <skeleton-loader-vue
                    type="rect"
                    :width="800"
                    :height="30"
                    animation="fade"
                    rounded
                  />
                </div>
              </div>

              <div v-if="isLoaded">
                <SkillsTab
                  :basicInfo="profile.basicInfo"
                  :projects="profile.projects"
                  @bio-updated="refreshIntro"
                  @skills-updated="refreshIntro"
                  @closed="refreshIntro(true)"
                />
                <ExperiencesTab
                  :experiences="profile.experiences"
                  :talent-email="profile.basicInfo.email"
                  @experiences-updated="refreshIntro"
                  @closed="refreshIntro(true)"
                />
                <EducationTab
                  :educations="profile.educations"
                  :talent-email="profile.basicInfo.email"
                  @educations-updated="refreshIntro"
                  @closed="refreshIntro(true)"
                />
                <CertificatesTab
                  :certificates="profile.certifications"
                  :talent-email="profile.basicInfo.email"
                  @certifications-updated="refreshIntro"
                  @closed="refreshIntro(true)"
                />
                <LanguagesTab
                  v-if="isLoaded"
                  :languages="profile.basicInfo.languages"
                  :talent-email="profile.basicInfo.email"
                  @languages-updated="refreshIntro"
                  @closed="refreshIntro(true)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showStartTourModal"
      hide-cta
      @update="showStartTourModal = false"
    >
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-text-center"
      >
        <img src="@/assets/images/Investment data-amico 1.svg" alt="poster" />
        <h3 class="tw-text-lg tw-text-[#422E87] tw-font-semibold">
          {{ $t("tourGuide.salaryUpdated") }}
        </h3>
        <p class="tw-text-sm tw-text-grey">
          {{ $t("tourGuide.salaryUpdatedText") }}
        </p>
        <button
          class="tw-px-12 tw-py-3 tw-bg-[#FFC629] tw-text-[#000000] tw-rounded-md"
          @click="kickOffTour()"
        >
          {{ $t("tourGuide.salaryUpdatedCTA") }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import introJs from "intro.js";
import "intro.js/introjs.css";
import { mapGetters } from "vuex";
import Steps from "../../../enums/WizzardSteps";
import { scrollToTop } from "../../../utils/utils";
import CertificatesTab from "./components/certificatesTab/certificatesTab.vue";
import ExperiencesTab from "./components/experiencesTab/experiencesTab.vue";
import EducationTab from "./components/educationTab/educationTab.vue";
import LanguagesTab from "./components/languagesTab/languagesTab.vue";
import Assessments from "./components/assessments/assessments.vue";
import ProfileCard from "./components/profileCard/profileCard.vue";
import SkillsTab from "./components/skillsTab/skillsTab.vue";
import Levels from "../../../static/LanguageLevel";
import Tabs from "./components/tabs/tabs.vue";
import "./profile.scss";
import Modal from "@/components/shared/modal/modal.vue";

export default {
  components: {
    ProfileCard,
    Tabs,
    SkillsTab,
    ExperiencesTab,
    EducationTab,
    CertificatesTab,
    LanguagesTab,
    Assessments,
    Modal,
  },
  data() {
    return {
      isLoaded: false,
      assessmentsLoaded: false,
      showStartTourModal: false,
      stepsData: [
        {
          element: "#availability",
          title: this.$t("tourGuide.availability"),
          intro: this.$t("tourGuide.availabilityText"),
          position: "top",
        },
        {
          element: "#salary",
          title: this.$t("tourGuide.salary"),
          intro: this.$t("tourGuide.salaryText"),
        },
        {
          element: "#profile-picture",
          title: this.$t("tourGuide.uploadProfilePhoto"),
          intro: this.$t("tourGuide.uploadProfilePhotoText"),
        },
        {
          element: "#edit-profile",
          title: this.$t("tourGuide.editProfile"),
          intro: this.$t("tourGuide.editProfileText"),
        },
        {
          element: "#assessments-list",
          title: this.$t("tourGuide.startAssessment"),
          intro: this.$t("tourGuide.startAssessmentText"),
        },
        {
          element: "#bio-edit-button",
          title: this.$t("tourGuide.editBio"),
          intro: this.$t("tourGuide.editBioText"),
        },
        {
          element: "#skills-edit-button",
          title: this.$t("tourGuide.editSkills"),
          intro: this.$t("tourGuide.editSkillsText"),
        },
        {
          element: "#experiences-edit-button",
          title: this.$t("tourGuide.editExperience"),
          intro: this.$t("tourGuide.editExperienceText"),
        },
        {
          element: "#education-edit-button",
          title: this.$t("tourGuide.editEducation"),
          intro: this.$t("tourGuide.editEducationText"),
        },
        {
          element: "#certificates-edit-button",
          title: this.$t("tourGuide.editCertificates"),
          intro: this.$t("tourGuide.editCertificatesText"),
        },
        {
          element: "#languages-edit-button",
          title: this.$t("tourGuide.editLanguages"),
          intro: this.$t("tourGuide.editLanguagesText"),
          position: 'left'
        },
      ],
      stepsNumbers: {
        availability: 1,
        salary: 2,
        "profile-picture": 3,
        "edit-profile": 4,
        "assessments-list": 5,
        "bio-edit-button": 6,
        "skills-edit-button": 7,
        "experiences-edit-button": 8,
        "education-edit-button": 9,
        "certificates-edit-button": 10,
        "languages-edit-button": 11,
      },
      previousStep: null,
      intro: null,
    };
  },
  async mounted() {
    await this.getProfileDataRequest();
    this.scrollToTop();
    document.querySelector(`body`).style.overflowY = "visible";
    this.isProfileCompleted();

    // Observe changes in the DOM to refresh intro.js
    const observer = new MutationObserver(() => {
      if (this.intro) {
        this.intro.refresh();
      }
    });

    // Start observing the entire document for changes
    observer.observe(document, { childList: true, subtree: true });

    // Clean up the observer when the component is destroyed
    this.$once("hook:beforeDestroy", () => {
      observer.disconnect();
    });
  },
  destroyed() {
    this.$store.dispatch("talentProfile/getAssessmentsResponse", []);
    this.intro.exit(true);

    if (this.intro) {
      this.intro.exit(true);
    }
  },
  computed: {
    profile() {
      return this.$store.getters["talentProfile/getProfileData"];
    },

    ...mapGetters({
      user: "auth/getUser",
      assessments: "talentProfile/getAssessments",
      redirectedFromTourGuide: "talentProfile/getRedirectedViaTourGuide",
    }),

    allProfileDataLoaded() {
      return this.isLoaded && this.assessmentsLoaded;
    },

    thereIsNoAssessments() {
      const assessmentsList = document.querySelector("#assessments-list");
      return assessmentsList && assessmentsList.offsetHeight == 0;
    },

    shouldHaveTourGuide() {
      return true;
      // return this.$route.query.status && this.$route.query.status === "tour";
    },
    steps() {
      let filteredSteps = this.stepsData;
      const bioEdit = document.querySelector("#bio-edit-button");

      if (this.thereIsNoAssessments) {
        filteredSteps = this.stepsData.filter(
          (step) => step.element !== "#assessments-list"
        );
      }

      if (!bioEdit || !bioEdit.offsetHeight) {
        if (this.thereIsNoAssessments) {
          filteredSteps[4].element = "#add-bio";
        } else {
          filteredSteps[5].element = "#add-bio";
        }
      }

      this.updateStepsNumbers(this.thereIsNoAssessments);

      return filteredSteps;
    },
  },
  watch: {
    allProfileDataLoaded(newVal) {
      if (!newVal) return;

      const skipTourGuide = localStorage.getItem("skip_tour_guide");
      if (!this.shouldHaveTourGuide || skipTourGuide) return;

      this.axios
        .get('api/tour-guide?key="talent_profile_tour_guide"')
        .then((data) => {
          const { step } = data.data.data;

          if (
            step &&
            this.stepsNumbers[step] < Object.keys(this.stepsNumbers).length
          ) {
            this.showStartTourModal = false;
            var tabsIds = null;

            if (this.thereIsNoAssessments)
              tabsIds = {
                6: "skills-edit-button",
                7: "pills-Experience-tab",
                8: "pills-Education-tab",
                9: "pills-Certificates-tab",
                10: "pills-languages-tab",
              };
            else
              tabsIds = {
                7: "skills-edit-button",
                8: "pills-Experience-tab",
                9: "pills-Education-tab",
                10: "pills-Certificates-tab",
                11: "pills-languages-tab",
              };

            if (this.thereIsNoAssessments)
              this.stepsNumbers = {
                availability: 1,
                salary: 2,
                "profile-picture": 3,
                "edit-profile": 4,
                "bio-edit-button": 5,
                "skills-edit-button": 6,
                "experiences-edit-button": 7,
                "education-edit-button": 8,
                "certificates-edit-button": 9,
                "languages-edit-button": 10,
              };

            if (
              this.stepsNumbers[step] > this.stepsNumbers["skills-edit-button"]
            ) {
              document
                .querySelector(`#${tabsIds[this.stepsNumbers[step]]}`)
                .click();
            }

            this.startTour(this.stepsNumbers[step]);
          } else if (
            !step &&
            this.$route.query.status &&
            this.$route.query.status === "tour"
          ) {
            this.showStartTourModal = true;
          } else if (!step && !skipTourGuide) {
            this.startTour();
          }
        })
        .catch((error) => {
          if (this.$route.query.status && this.$route.query.status === "tour") {
            this.showStartTourModal = true;
          } else {
            this.startTour();
          }
          console.error(error);
        });
    },
  },
  methods: {
    scrollToTop,
    forceUpdate() {
      const originalWidth = window.innerWidth;
      window.innerWidth = originalWidth + 1; // Simulate a slight resize
      setTimeout(() => {
        window.innerWidth = originalWidth; // Reset to the original size
        window.dispatchEvent(new Event("resize"));
      }, 0);
    },
    updateStepsNumbers(noAssessment) {
      if (noAssessment) {
        this.stepsNumbers = {
          availability: 1,
          salary: 2,
          "profile-picture": 3,
          "edit-profile": 4,
          "bio-edit-button": 5,
          "skills-edit-button": 6,
          "experiences-edit-button": 7,
          "education-edit-button": 8,
          "certificates-edit-button": 9,
          "languages-edit-button": 10,
        };
      } else {
        this.stepsNumbers = {
          availability: 1,
          salary: 2,
          "profile-picture": 3,
          "edit-profile": 4,
          "assessments-list": 5,
          "bio-edit-button": 6,
          "skills-edit-button": 7,
          "experiences-edit-button": 8,
          "education-edit-button": 9,
          "certificates-edit-button": 10,
          "languages-edit-button": 11,
        };
      }
    },
    kickOffTour() {
      this.showStartTourModal = false;
      setTimeout(() => {
        document.querySelector(`body`).style.overflowY = "visible";
        this.startTour();
      }, 1000);
    },
    refreshIntro(reload) {
      if (!this.intro) return;

      if (reload) document.querySelector(`body`).style.overflowY = "visible";
      else window.location.reload();
    },
    async startTour(stepNumber = null) {
      this.intro = introJs();
      this.intro.setOptions({
        steps: this.steps,
        showStepNumbers: true,
        showBullets: false,
        exitOnOverlayClick: false,
        disableInteraction: false,
        stepNumbersOfLabel: "/",
        doneLabel: this.$t("tourGuide.finish"),
        nextLabel: this.$t("tourGuide.next"),
        prevLabel: this.$t("tourGuide.previous"),
        skipLabel: this.$t("tourGuide.skip"),
      });

      if (stepNumber !== null) {
        this.intro.goToStepNumber(stepNumber).then(() =>
          this.intro.start().then(() => {
            const skipButton = document.querySelector(".introjs-skipbutton");
            skipButton.addEventListener("click", () => {
              localStorage.setItem("skip_tour_guide", true);
            });
          })
        );
      } else {
        this.intro.start().then(() => {
          const skipButton = document.querySelector(".introjs-skipbutton");
          skipButton.addEventListener("click", () => {
            localStorage.setItem("skip_tour_guide", true);
          });
        });
      }

      this.intro.onchange((target) => {
        let step = target.getAttribute("id");
        const currentStep = this.intro.currentStep() + 1;
        if (step == "add-bio") {
          step = "bio-edit-button";
        }

        // const previousButton = document.querySelector('.introjs-prevbutton');
        var tabsIds = null;

        if (this.thereIsNoAssessments)
          tabsIds = {
            6: "skills-edit-button",
            7: "pills-Experience-tab",
            8: "pills-Education-tab",
            9: "pills-Certificates-tab",
            10: "pills-languages-tab",
          };
        else
          tabsIds = {
            7: "skills-edit-button",
            8: "pills-Experience-tab",
            9: "pills-Education-tab",
            10: "pills-Certificates-tab",
            11: "pills-languages-tab",
          };

        if (!this.previousStep) {
          this.previousStep = currentStep;
        }

        if (currentStep == this.stepsNumbers["skills-edit-button"]) {
          this.tabsChanged = false;
          document.querySelector(`#pills-home-tab`).click();
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 300);
        } else if (
          currentStep > this.stepsNumbers["skills-edit-button"] &&
          currentStep < this.stepsNumbers["languages-edit-button"]
        ) {
          this.tabsChanged = true;
          document.querySelector(`#${tabsIds[currentStep]}`).click();
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 300);
        } else if (currentStep == this.stepsNumbers["languages-edit-button"]) {
          document.querySelector(`#${tabsIds[currentStep]}`).click();
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 300);
        } else if (currentStep < this.stepsNumbers["skills-edit-button"]) {
          this.forceUpdate();
        }

        this.previousStep = currentStep;
        this.axios.post("api/tour-guide", {
          key: "talent_profile_tour_guide",
          step,
          is_completed: true,
        });

        if (window.gtag)
          window.gtag("event", "talent_profile_tour_guide", {
            current_step: currentStep,
            total_steps: this.steps.length,
            step_name: this.steps[currentStep]?.element,
            email: this.user?.email,
          });
      });

      // this.intro.onexit(() => {
      //   localStorage.setItem("skip_tour_guide", true);
      // });

      this.intro.oncomplete(() => {
        if (window.gtag) {
          window.gtag("event", "talent_profile_tour_guide", {
            current_step: this.intro.currentStep(),
            total_steps: this.steps.length,
            step_name: this.steps[this.intro.currentStep()].element,
            email: this.user?.email,
          });
        }
      });
    },
    handleRedirection(path) {
      if (this.intro)
        this.intro.exit(true).then(() => {
          this.$router.push({ path });
        });
      else this.$router.push({ path });
    },
    async getProfileDataRequest() {
      this.$store.dispatch("talentProfile/getProfileDataRequest").then(() => {
        this.isLoaded = true;
       
        if(window.gtag) window.gtag("event", "applicant profile complete", {
          user_type: 'Talent',
          step_number: 'Step 8',
          step_name: 'Talent Profile',
          first_name: this.user?.first_name,
          last_name: this.user?.last_name,
          phone_number: this.user?.phone_number,
          country: this.profile.basicInfo.country?.name,
          nationality: this.profile.basicInfo.nationality?.name,
          email: this.user?.email,
        })
      });
    },
    getLanguageLevel(id) {
      if (id) {
        return Levels.map((level) => ({
          ...level,
          name: this.$t(`languageLevel.${[level.name]}`),
        })).find((element) => element.id === id).name;
      }
    },
    checkLengthOfLanguages() {
      // return this.languages.length > 1;
    },
    isProfileCompleted() {
      let step = JSON.parse(localStorage.getItem("step"));
      return step == Steps.PROFILE
        ? null
        : this.$router.push({ name: "Wizard" });
    },
  },
};
</script>

<style lang="scss">
.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.introjs-showElement {
  z-index: 9999999 !important;
}
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  z-index: 9999999 !important;
  position: relative;
}
.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: #fff;
  opacity: 0;
}
.introjs-relativePosition {
  position: relative;
}
.introjs-helperLayer {
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}
.introjs-helperLayer,
.introjs-helperLayer *,
.introjs-helperLayer :after,
.introjs-helperLayer :before {
  box-sizing: content-box;
}
.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out;
}
.introjs-helperNumberLayer,
.introjs-tooltipReferenceLayer,
.introjs-tooltipReferenceLayer * {
  font-family: Helvetica Neue, Inter, ui-sans-serif, Apple Color Emoji,
    Helvetica, Arial, sans-serif;
}
.introjs-helperNumberLayer {
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.introjs-arrow {
  border: 5px solid transparent;
  content: "";
  position: absolute;
}
.introjs-arrow.top {
  top: -10px;
  left: 10px;
  border-bottom-color: #fff;
}
.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color: #fff;
}
.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #fff;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color: #fff;
}
.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-left-color: #fff;
}
.introjs-arrow.bottom {
  bottom: -10px;
  left: 10px;
  border-top-color: #fff;
}
.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color: #fff;
}
.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: #fff;
}
.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color: #fff;
}
.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-right-color: #fff;
}
.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
  transition: opacity 0.1s ease-out;
}
.introjs-tooltiptext {
  padding: 20px;
}
.introjs-dontShowAgain {
  padding-left: 20px;
  padding-right: 20px;
}
.introjs-dontShowAgain input {
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  display: inline;
  width: 10px;
  height: 10px;
}
.introjs-dontShowAgain label {
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  margin: 0 0 0 5px;
  padding: 0;
  background-color: #fff;
  color: #616161;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.introjs-tooltip-title {
  font-size: 18px;
  width: 90%;
  min-height: 1.5em;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1.5;
}
.introjs-tooltip-header {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  min-height: 1.5em;
}
.introjs-tooltipbuttons {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  text-align: right;
  white-space: nowrap;
}
.introjs-tooltipbuttons:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0.5rem 1rem;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  font-size: 14px;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #f4f4f4;
  border-radius: 0.2em;
  zoom: 1;
  display: inline;
}
.introjs-button:hover {
  border-color: #9e9e9e;
  background-color: #e0e0e0;
}
.introjs-button:focus,
.introjs-button:hover {
  outline: 0;
  text-decoration: none;
  color: #212121;
}
.introjs-button:focus {
  background-color: #eee;
  box-shadow: 0 0 0 0.2rem hsla(0, 0%, 62%, 0.5);
  border: 1px solid #616161;
}
.introjs-button:active {
  outline: 0;
  text-decoration: none;
  background-color: #e0e0e0;
  border-color: #9e9e9e;
  color: #212121;
}
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.introjs-skipbutton {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #616161;
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
.introjs-skipbutton:focus,
.introjs-skipbutton:hover {
  color: #212121;
  outline: 0;
  text-decoration: none;
}
.introjs-prevbutton {
  float: left;
}
.introjs-nextbutton {
  float: right;
}
.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
  color: #9e9e9e;
  border-color: #bdbdbd;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}
.introjs-hidden {
  display: none;
}
.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 0 auto 0;
  padding: 0;
  display: inline-block;
}
.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}
.introjs-bullets ul li a {
  transition: width 0.1s ease-in;
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}
.introjs-bullets ul li a:focus,
.introjs-bullets ul li a:hover {
  width: 15px;
  background: #999;
  text-decoration: none;
  outline: 0;
}
.introjs-bullets ul li a.active {
  width: 15px;
  background: #999;
}
.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #e0e0e0;
}
.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}
.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}
.introjs-fixedTooltip {
  position: fixed;
}
.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: 0 0;
  width: 20px;
  height: 15px;
  cursor: pointer;
}
.introjs-hint:focus {
  border: 0;
  outline: 0;
}
.introjs-hint:hover > .introjs-hint-pulse {
  background-color: rgba(60, 60, 60, 0.57);
}
.introjs-hidehint {
  display: none;
}
.introjs-fixedhint {
  position: fixed;
}
@keyframes introjspulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }
  to {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 transparent;
  }
}
.introjs-hint-pulse {
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: hsla(0, 0%, 53.3%, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
  animation: introjspulse 2s infinite;
}
.introjs-hint-no-anim .introjs-hint-pulse {
  animation: none;
}
.introjs-hint-dot {
  box-sizing: content-box;
  background: 0 0;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -18px;
  left: -18px;
  z-index: 1;
  opacity: 0;
}
.introjs-helperLayer {
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 1) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.2) 0px 0px 0px 5000px !important;
}



.introjs-tooltip {
  width: calc(100vw - 30px);
  max-width: 645px;
  height: 110px;
  border-radius: 10px;

  @media (max-width: 501px) {
    height: auto;
  }
}

html[dir="rtl"] .introjs-tooltip * {
  font-family: "Tajawal", sans-serif;
}

html[dir="rtl"] .introjs-tooltip {
  max-width: 590px;
}

.introjs-arrow {
  display: none;
}

.introjs-tooltip-header {
  margin-bottom: 15px;
  @media (max-width: 501px) {
    margin-bottom: 5px;
  }
}

.introjs-tooltiptext {
  position: absolute;
  top: 38px;
  left: 0;
  color: #9f9d9d;
  font-size: 14px;
  max-width: 70%;

  @media (max-width: 501px) {
    position: relative;
    top: 0;
    left: 0;
    max-width: auto;
  }
}

html[dir="rtl"] .introjs-tooltiptext {
  right: 0;
  left: auto;
}

html[dir="rtl"] .introjs-skipbutton {
  left: 0;
  right: auto;
}

.introjs-helperNumberLayer {
  position: absolute;
  top: 2px;
  right: 55px;
  color: #3bd4ae;
  font-size: 16px;

  &::before {
    content: "Step";
    font-size: 16px;
    color: #3bd4ae;
    padding: 5px;
    border-radius: 5px;
  }
}
html[dir="rtl"] .introjs-helperNumberLayer {
  right: auto;
  left: 55px;
  direction: ltr;

  &::after {
    content: "الخطوة";
    padding-left: 5px;
  }
  &::before {
    content: "";
  }
}

.introjs-skipbutton {
  font-size: 16px;
  margin: 0 5px;
  font-weight: 500;
  text-decoration: underline;
}

.introjs-tooltipbuttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  border-top: none;
}

.introjs-button {
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-shadow: none;

  &:hover,
  &:focus,
  &:active {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.introjs-tooltip-title {
  @media (max-width: 501px) {
    font-size: 16px;
  }
}

.introjs-nextbutton {
  background-color: #422e87;
  color: #fff;
  border: 1px solid #422e87;

  &:hover,
  &:focus,
  &:active {
    background-color: #422e87;
    color: #fff;
    outline: 0 !important;
    border: 1px solid #422e87;
  }
}

.introjs-prevbutton {
  color: #422e87;
  background-color: #fff;
  border: 1px solid #422e87;

  &:hover,
  &:focus,
  &:active {
    color: #422e87;
    background-color: #fff;
    border: 1px solid #422e87;
  }
}

.introjs-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  }
</style>
